import Cerberus from "./Img/Cerberus.png";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Header />
      <img src={Cerberus} className="logo" alt="Cerberus" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "5%",
          border: "2px solid grey", // Combined shorthand for border properties
          borderRadius: "10px",
          borderRadius: "10px",
          padding: "2%",
        }}
      >
        <p>
          Nox Cerberus is an advanced OAuth, OICD, FIDO2 and WebAuthN
          authentication controller API. It combines the industry leading
          authentication practices of OAuth and OICD with the latest in FIDO2
          and WebAuthN cybersecurity practices. Nox Cerberus is designed to be a
          secure, reliable and easy to use gateway controller for web and mobile
          applications. Production nearing completion...
        </p>
        <p>
          OAuth is the default standard for login and login to other websites
          without sharing your actual password.
        </p>
        <p>
          OICD builds on OAuth but adds more functionality to prove who you are
          (authentication). While OAuth is about "permissions," OIDC is about
          "identity."
        </p>
        <p>
          FIDO2 aims to replace passwords altogether. It lets you log in using
          physical security keys, biometrics (like fingerprints), or devices you
          already have (like your smartphone).
        </p>
        <p>
          WebAuthN is a part of FIDO2. It’s the technology that makes
          passwordless logins work on the web. It lets websites use
          FIDO2-compatible devices to log you in securely.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default App;
