import React from "react";

const Header = () => (
  <header
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: "5%",
      paddingRight: "5%",
    }}
  >
    <h1>Nox Cerberus</h1>
    <p>Nox Cerberus Authentication by Nox Ignis Software</p>
  </header>
);

export default Header;
